import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";

const Home = () => {
  const [email, setEmail] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const containerVariants = {
    hidden: { opacity: 0, transition: { duration: 0.5 } },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, scale: 0.9, rotate: -10 },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: { type: "spring", stiffness: 200 },
    },
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    emailjs
      .sendForm(
        "service_kges9he",
        "template_4dfw585",
        e.target,
        "p27N5UaaBhGZyMQMP"
      )
      .then(
        (result) => {
          setIsLoading(false); // Stop loading
          setPopupVisible(true); // Show the popup on success
          setTimeout(() => setPopupVisible(false), 3000); // Hide after 3 seconds
        },
        (error) => {
          setIsLoading(false); // Stop loading on error
          alert("Failed to send email, please try again.");
        }
      );

    setEmail(""); // Clear the email input field
  };

  return (
    <section className="bg-white py-16">
      <motion.div
        className="container mx-auto px-4 flex flex-col lg:flex-row justify-between items-center space-y-8 lg:space-y-0"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        {/* Left Section - Text and Form */}
        <div className="max-w-lg text-center lg:text-left">
          <motion.h1
            className="text-4xl sm:text-5xl font-bold text-black leading-tight mb-6"
            variants={itemVariants}
          >
            🚀 Welcome to Snaazr: The Future of Shopping Awaits! 🛍️
          </motion.h1>

          <motion.p
            className="text-gray-600 text-base sm:text-lg mb-8"
            variants={itemVariants}
          >
            Picture this: shopping that’s not just a chore but an adventure! With Snaazr, dive into a vibrant world where you discover products through short, captivating videos. Are you ready to revolutionize your shopping spree?
          </motion.p>

          <form onSubmit={sendEmail} className="flex flex-col sm:flex-row items-center mb-6 space-y-4 sm:space-y-0 sm:space-x-4">
            <motion.input
              type="email"
              name="user_email"
              placeholder="Your email, please!"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-300 rounded-lg px-4 py-2 w-full sm:w-64 text-gray-700 focus:outline-none focus:ring-2 focus:ring-black focus:border-transparent transition duration-200"
              variants={itemVariants}
              required
            />
            <motion.button
              type="submit"
              className="bg-black text-white px-4 py-2 rounded-md text-sm sm:text-base hover:bg-gray-800 relative"
              variants={itemVariants}
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0016 0A8 8 0 004 12z" />
                  </svg>
                  Sending...
                </span>
              ) : (
                "🎉 Join Snaazr! 🎉"
              )}
            </motion.button>
          </form>

          {/* Popup Message */}
          {popupVisible && (
            <motion.div
              className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-lg z-50"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
            >
              Thank you, you're added to our waitlist!
            </motion.div>
          )}

          <div className="flex justify-center sm:justify-start space-x-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
              alt="VISA"
              className="h-6"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg"
              alt="Mastercard"
              className="h-6"
            />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
              alt="PayPal"
              className="h-6"
            />
          </div>
        </div>

        {/* Right Section - Illustration Placeholder */}
        <div className="mt-10 lg:mt-0 lg:ml-16">
          <motion.img
            src="https://static.vecteezy.com/system/resources/previews/021/179/543/original/online-shopping-icon-using-mobile-phone-free-png.png"
            alt="Illustration"
            className="w-full max-w-xs lg:max-w-full h-auto mx-auto lg:mx-0"
            variants={itemVariants}
          />
        </div>
      </motion.div>

      {/* Additional Info Section */}
      <motion.div
        className="container mx-auto px-4 mt-16 text-center"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h2
          className="text-3xl font-semibold mb-4"
          variants={itemVariants}
        >
          🎊 Are You Ready to Level Up Your Shopping Game? 🎊
        </motion.h2>
        <motion.p
          className="text-gray-600 text-base sm:text-lg mb-8"
          variants={itemVariants}
        >
          Snaazr isn’t just changing the way you shop; it’s creating a shopping extravaganza! Dive into an immersive experience where every scroll is a discovery. Let’s transform browsing into a delightful journey of excitement and wonder!
        </motion.p>
      </motion.div>
    </section>
  );
};

export default Home;


// Test commit