import React from 'react'
import Home from './component/Home/home'
import Header from './component/Header/header'
import Footer from './component/Footer/footer'




function App() {
  return (
    <div className=''>
      <Header />
      <Home />
      <Footer />
    </div>
  )
}

export default App