import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white border-b border-gray-200 shadow-sm">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center">
          <div className="flex items-center space-x-2">
            {/* Circle */}
            <div className="w-8 h-8 bg-blue-500 rounded-full"></div>
            {/* Snaazr text */}
            <div className="text-black font-semibold text-xl">
              Snaazr
            </div>
          </div>
        </div>

        {/* Hamburger Icon for Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Links - hidden on mobile, shown on larger screens */}
        <ul className="hidden md:flex space-x-8 text-gray-700">
          <li>
            <a href="#" className="hover:text-black">
              About us
            </a>
          </li>
          <li>
            <a href="#" className="hover:text-black">
              For Sellers
            </a>
          </li>
          <li>
            <a href="#" className="hover:text-black">
              For Influencers
            </a>
          </li>
          <li>
            <a href="#" className="hover:text-black">
              FAQs
            </a>
          </li>
        </ul>

        {/* Join Wishlist button */}
        <div className="hidden md:flex">
          <a
            href="#"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
          >
            Join Wishlist
          </a>
        </div>
      </div>

      {/* Mobile Menu - visible when `isOpen` is true */}
      {isOpen && (
        <div className="md:hidden">
          <ul className="space-y-4 px-4 py-4 text-gray-700">
            <li>
              <a href="#" className="block hover:text-black">
                About us
              </a>
            </li>
            <li>
              <a href="#" className="block hover:text-black">
                For Sellers
              </a>
            </li>
            <li>
              <a href="#" className="block hover:text-black">
                For Influencers
              </a>
            </li>
            <li>
              <a href="#" className="block hover:text-black">
                FAQs
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Join Wishlist
              </a>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
